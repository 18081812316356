<template>
  <div class="centerwrap">
    <div class="centerbox">
      <div class="detailbox" v-if="teamInfo.Team">
        <div>
          <span class="left">
            <img :src="teamInfo.Cover" alt />
          </span>
          <div class="right">
            <h2 class="titleh2" v-text="teamInfo.Team"></h2>
            <div class="setbox">
              <el-tooltip class="item" effect="dark" content="加入该团队" placement="top" v-if="ismember && ismember!==1">
                <span class="iconfont iconbianzu7" @click="handelJoinTeam(tid)"></span>
              </el-tooltip>
              <span class="iconfont iconbianzu8 iconaddbtn"  v-if="ismember && ismember==1">
                <ul class="setessay">
                  <li>
                    <router-link :to="{name:'bookInfo',query:{tid:tid}}" tag="span">新建图书</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'columnInfo',query:{tid:tid}}" tag="span">添加专栏</router-link>
                  </li>

                  <li> <router-link :to="{name:'specialColumnEdit'}" tag="span">发布专栏文章</router-link></li>
                </ul>
              </span>
              <el-tooltip class="item" effect="dark" content="浏览器打开" placement="top" v-if="isElection" @click="handelGopage('www.baidu.com')">
                <span class="iconfont iconbianzu9"></span>
              </el-tooltip>
              
            </div>
            <p class="textp" v-text="teamInfo.Slogan"></p>
          </div>
        </div>
      </div>
      <div class="detailbox detailboxgrey" v-else>
        <div>
          <span class="left"></span>
          <div class="right">
            <h2 class="titleh2"></h2>
            <div class="setbox">
              <span class="iconfont iconbianzu9"></span>
              <span class="iconfont iconbianzu8"></span>
              <span class="iconfont iconbianzu7"></span>
            </div>
            <p class="textp"></p>
          </div>
        </div>
      </div>
      <div class="tabnav" v-if="isleader>-1">
        <h2
          v-for="(item,i ) in tabnavText"
          v-text="item.tabtitle"
          :class="{active:i==tabIndex}"
          @click="handelTab(i,'tabIndex',item)"
          v-if="item.tabtitle!=='设置' ||  (isleader==1 &&item.tabtitle=='设置')"
        ></h2>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import FoldTop from "@/components/Foldtop/foldTop";
import { teamInfoRequest,teamJoinRequest } from "@/api/project.js";
export default {
  components: {
    FoldTop,
  },
  created() {
    this.getteamInfo(this.tid);
    if (this.$route.name == "teamsBook") {
      this.tabIndex = 0;
    } else if (this.$route.name == "teamsColumn") {
      this.tabIndex = 1;
    } else if (this.$route.name == "teamsMember") {
      this.tabIndex = 2;
    } else if (this.$route.name == "teamsOrder") {
      this.tabIndex = 3;
    } else {
      this.tabIndex = 4;
    }
  },
  data() {
    return {
      tabnavText: [
        {
          tabtitle: "图书",
          link: "teamsBook",
        },
        {
          tabtitle: "专栏",
          link: "teamsColumn",
        },
        {
          tabtitle: "成员",
          link: "teamsMember",
        },
        {
          tabtitle: "订单",
          link: "teamsOrder",
        },
        {
          tabtitle: "设置",
          link: "teamsSet",
        },
      ],
      tabIndex: "",
      teamInfo: {},
      isleader: -1,
      ismember:0
    };
  },
  methods: {
    handelTab(index, a, item) {
      this[a] = index;
      this.$router.push({ name: item.link, params: { tid: this.tid } });
    },
    async getteamInfo(tid) {
      try {
        const result = await teamInfoRequest({
          tid: tid,
        });
        if (result.status == 200) {
          this.teamInfo = result.data.team;
          this.isleader = result.data.isleader; // isleader = 0 不显示设置按钮，为1时显示
          this.ismember = result.data.ismember; // isleader = 0 不显示设置按钮，为1时显示

          this.$store.commit("setTeamIsmember", result.data.ismember);
        }
      } catch (err) {}
    },
    async joinTeam(tid, reason) {
      try {
        const result = await teamJoinRequest({
          tid: tid,
          reason: reason
        });
        if (result.status == 200) {
          this.$message({
            type: "success",
            message: `${result.message}`
          });
        } else {
          this.$message({
            type: "info",
            message: `${result.message}`
          });
        }
      } catch (err) {}
    },
    handelJoinTeam(id) {
      var _this = this;
      this.$prompt("请输入申请理由", "加入该团队", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /[\s\S]{1,120}/,
        inputErrorMessage: "请输入申请理由",
        center: true
      })
        .then(({ value }) => {
          _this.joinTeam(id, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入"
          });
        });
    },
    handelGopage(link) {
      // var userAgent = navigator.userAgent.toLowerCase();
      // var isElection = userAgent.indexOf(" electron/") > -1;
      if (this.isElection) {
        const { shell } = window.require("electron").remote;
        shell.openExternal(link);
      }
    }
  },
  computed: {
    tid() {
      return this.$route.params.tid || ""; //当前项目id
    },
    isElection(){
      var userAgent = window.navigator.userAgent.toLowerCase();
      return userAgent.indexOf(" electron/") > -1;
    }
  },
  watch: {
    $route(newroute, oldroute) {
      if (newroute.params.tid !== oldroute.params.tid) {
        return (this.tabIndex = 0);
      } else {
      }
    },
  },
};
</script>
<style  lang="less" scoped>
.centerwrap {
  position: relative;
  margin: -15px 0;
  min-height: 100%;
}
.centerbox{
  width:1200px;
    background: #fff;
  margin:30px auto;
      box-shadow: 0px 0px 10px #e8e8e8;
}
.detailbox {
  padding: 30px;
  background: #fff;
  background-size: cover;
overflow: hidden;
  .left {
    width: 142px;
    height: 114px;
    margin-right: 20px;
    border-radius: 4px;
    overflow: hidden;
    float: left;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    flex: 1;
    cursor: pointer;
    color: #222;
    padding-left: 90px;
    .titleh2 {
      font-size: 16px;
      margin-bottom: 5px;
      height: 22px;
    }
    .textp {
      font-size: 13px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-right: 190px;
      height: 44px;
    }
  }
  .setbox {
    float: right;
    width: 170px;
    .iconfont {
      float: right;
      font-size: 36px;
      color: #3385ff;
      margin-right: 20px;
    }
  }
}
.detailboxgrey {
  .left {
    background: rgba(234, 234, 234, 0.3);
  }
  .right .titleh2 {
    width: 170px;
    background: rgba(234, 234, 234, 0.3);
  }
  .right .textp {
    width: 60%;
    background: rgba(234, 234, 234, 0.3);
  }
}
/deep/.el-menu--horizontal {
  margin: 0 15px;
  & > .el-menu-item {
    border-bottom: 2px solid #16aed0;
    margin: 0 25px 0px 0;
    padding: 4px;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    color: #222;
    &.is-active {
      color: #16aed0;
    }
  }
}
.tabnav {
  border-bottom: 1px solid #ddd;
  margin: 0 0 15px 0;
  padding: 0 30px;
  & > h2 {
    border-bottom: 2px solid #16aed0;
    margin: 0 25px 0px 0;
    padding: 4px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    color: #222;
    border: none;
    display: inline-block;
    cursor: pointer;
    &.active {
      color: #16aed0;
      border-bottom: 2px solid #23b7e5;
    }
  }
}
.iconaddbtn:hover {
  .setessay {
    display: block;
  }
}
.iconaddbtn{
  position: relative;
}
.setessay {
  position: absolute;
  background: #fff;
  color: #606266;
  border: 1px solid #ececec;
  width: 120px;
  line-height: 40px;
  right: 0;
  top: 47px;
  display: none;
  z-index: 100!important;
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -9px;
    z-index: 1;
    right: 10px;
  }
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #ececec;
    position: absolute;
    top: -11px;
    right: 10px;
  }
  li {
    border-bottom: 1px dotted #ececec;
    margin-bottom: -1px;
    padding: 0 10px;
    font-size: 13px;
    text-align: center;
    &:hover {
      background: #d0f6ff;
      color: #459caf;
    }
  }
}
</style>